import React from "react";
import styled from "styled-components";
import { Devices, Colors } from "../../styles/variables";
import { EzDivisor, RespContainer } from "../../styles/common";
import { ServicesWeOfferType } from "../../shared/interfaces/graphql.interface";
import { Link } from "gatsby";
import { FontelloIcon } from "../../styles/CommonComponents";
import EzReadMore from "./EzReadMore";
import SVG from "react-inlinesvg";

interface Props {
  services_we_offer: ServicesWeOfferType;
  narrowBottomMargin?: boolean;
}

const ServicesWeOffer = ({ services_we_offer, narrowBottomMargin }: Props) => {
  return (
    <Services narrowBottomMargin={narrowBottomMargin}>
      <ServicesTitle>Services We Offer</ServicesTitle>
      <ServicesTitleDivisor alwaysBlue />
      <ItemsBox>
        {services_we_offer.map((service, idx) => (
          <ServiceWrapper to={`/services/${service.item.slug}`} key={idx}>
            <ServiceIconWrapper>
              <ServiceIcon src={service.item.icon_svg} />
              {console.log('service',service)}
            </ServiceIconWrapper>
            <ServiceTitle>{service.item.title}</ServiceTitle>
            <ServiceSubtitle>{service.item.subtitle}</ServiceSubtitle>
            <ServicesReadMore>
              <EzReadMore customText={"Learn More"} />
            </ServicesReadMore>
          </ServiceWrapper>
        ))}
      </ItemsBox>
    </Services>
  );
};

export default ServicesWeOffer;

const Services = styled(RespContainer)<{ narrowBottomMargin: boolean }>`

  /* background-color: ${Colors.gray7}; */

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    padding-bottom: ${({ narrowBottomMargin }) =>
      narrowBottomMargin ? "60px" : "100px"};
  }
`;

const ServiceWrapper = styled(Link)`
  padding-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 576px;
  border-radius: 8px;

  position: relative;
  padding-bottom: 48px;

  &:hover {
    text-decoration: none;
  }

  /* >= 768px; */
  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    flex: 0 0 47%;
    max-width: unset;
    border: solid 1px transparent;

    &:hover {
      background-color: ${Colors.white};
      box-shadow: 0 1px 60px 0 rgba(12, 38, 214, 0.1);
      border: solid 1px #f9faff;
      svg {
        g,
        path {
          fill: ${Colors.sharpBlue};
        }
      }
      h3 {
        color: ${Colors.sharpBlue};
      }
    }

    padding-left: 15px;
    padding-right: 15px;
    margin-right: 30px;
    &:nth-child(2n) {
      margin-right: unset;
    }
  }

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    flex: 0 0 31%;

    padding-left: 15px;
    padding-right: 15px;
    margin-right: 30px;
    &:nth-child(2n) {
      margin-right: 30px;
    }
    &:nth-child(3n) {
      margin-right: unset;
    }
  }
`;

const ServicesTitle = styled("h2")`
  text-align: center;
  width: 100%;
  font-size: 30px;
  font-weight: 600;
  color: ${Colors.dodgeBlue2};
  margin-top: 38px;
  margin-bottom: 14px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    font-size: 60px;
    margin-top: 67px;
    margin-bottom: 28px;
  }
`;

const ServicesTitleDivisor = styled(EzDivisor)`
  width: 100%;
  margin-bottom: 40px;
  &::after {
    left: 50%;
    transform: translateX(-50%);
  }

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-bottom: 56px;
  }
`;

const ItemsBox = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  /* >= 768px; */
  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    flex-direction: row;
  }
`;

const ServiceIconWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  margin-bottom: 22px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
  }
`;

const ServiceIcon = styled(SVG)`
  height: 100%;
  min-height: 100%;
`;

const ServiceTitle = styled("h3")`
  font-size: 24px;
  color: ${Colors.dodgeBlue2};
  margin-bottom: 20px;
  text-align: center;
  font-weight: 600;
`;

const ServiceSubtitle = styled("h4")`
  font-size: 15px;
  color: ${Colors.solidGray};
  margin-bottom: 20px;
  text-align: center;
  line-height: 190%;
`;

const ServicesReadMore = styled("span")`
  font-size: 18px;
  color: ${Colors.sharpBlue};
  margin-bottom: 20px;
  font-weight: 600;
  position: absolute;
  bottom: 0;
`;
